import firebase from 'firebase/app';
import 'firebase/firestore';
import { Game, gameConverter } from '../model/game';

export class FirestoreService {

    static readonly GAMES_COLLECTION: string = 'games';

    static isInitialized: boolean = false;

    static initialize(): void {
        const app: firebase.app.App = firebase.initializeApp({
            apiKey: "AIzaSyBsg8fpGjCzDSP4SKrtE5UqvAZoEztzCX4",
            authDomain: "doko-jahresliste.firebaseapp.com",
            databaseURL: "https://doko-jahresliste.firebaseio.com",
            projectId: "doko-jahresliste",
            storageBucket: "doko-jahresliste.appspot.com",
            messagingSenderId: "224632056994",
            appId: "1:224632056994:web:a4ac4ed4b232ee7f0cbbfb"
        });
        this.isInitialized = app !== undefined;
    }

    static async readGames(): Promise<Array<Game>> {
        const startDate: Date = new Date('2020-01-01');
        const endDate: Date = new Date('2020-12-31');
        const querySnapshot: firebase.firestore.QuerySnapshot<Game> = await firebase
            .firestore()
            .collection(this.GAMES_COLLECTION)
            .withConverter(gameConverter)
            .where('date', '>=', startDate)
            .where('date', '<=', endDate)
            .orderBy('date')
            .get();
        return querySnapshot.docs
            .map((val: firebase.firestore.QueryDocumentSnapshot<Game>) => val.data());
    }

}
