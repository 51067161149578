export class Score {

    static fromMap(map: {name: string, score: number}): Score {
        return new Score(map.name, map.score);
    }

    name: string;
    points: number;

    constructor(name: string, points: number) {
        this.name = name;
        this.points = points;
    }

    toMap(): Map<string, any> {
        const map: Map<string, any> = new Map();
        map.set('name', this.name);
        map.set('score', this.points);
        return map;
    }

}
