import React from 'react';

interface InfoBoxProps {
    leading: JSX.Element | String;
    title: string;
    subtitle: string;
}

export class InfoBox extends React.Component<InfoBoxProps, {}> {

    render() {
        return (
            <div className="row mb-4 align-items-center">
                <div className="col-6 text-end">
                    {this.props.leading instanceof Object ? this.props.leading : <h1 className="size-60 mb-0">{this.props.leading}</h1>}
                </div>
                <div className="col-6 ps-0">
                    <div className="row align-items-end">
                        <div className="col text-start">
                            <h1 className="mb-0">{this.props.title}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-start">
                            <p className="mb-0">{this.props.subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}