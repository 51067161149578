import { BumpInputDatum, BumpInputSerie } from '@nivo/bump';
import { Datum, Serie } from '@nivo/line';
import { Game } from '../model/game';
import { Score } from '../model/score';

export class DataManager {

    private static instance: DataManager;

    static get(): DataManager {
        if (this.instance === undefined) {
            this.instance = new DataManager();
        }
        return this.instance;
    }

    private games: Array<Game> = [];

    private players: Array<string> = [];

    private corePlayers: Array<string> = [];

    private pointsPerPlayer: Map<string, number> = new Map();

    private constructor() { }

    getGames(): Array<Game> {
        return this.games;
    }

    setGames(games: Array<Game>): void {
        this.games = games;
        this.updateData();
    }

    getPlayers(onlyCore: boolean = true): Array<string> {
        return onlyCore ? this.corePlayers : this.players;
    }

    getScores(onlyCore: boolean = true): Array<Score> {
        let scores: Array<Score> = [];
        this.pointsPerPlayer.forEach((val: number, key: string) => {
            if (onlyCore && !this.corePlayers.includes(key)) {
                return;
            }
            const score: Score = new Score(key, val);
            scores.push(score);
        });
        scores = scores.sort((a: Score, b: Score) => b.points - a.points);
        return scores;
    }

    getRankingBumpData(): Array<BumpInputSerie> {
        const player2BumpData: Map<string, Array<BumpInputDatum>> = new Map();
        const currentScores: Array<Score> = [];
        for (const cp in this.corePlayers) {
            player2BumpData.set(this.corePlayers[cp], []);
        }
        this.games.forEach((game: Game, index: number) => {
            for (const s in game.scores) {
                const score: Score = game.scores[s];
                if (!this.corePlayers.includes(score.name)) continue;
                const idx: number = currentScores.findIndex((val: Score) => val.name === score.name);
                if (idx === -1) {
                    currentScores.push(new Score(score.name, +score.points));
                } else {
                    currentScores[idx].points = +currentScores[idx].points + +score.points;
                }
            }
            currentScores.sort((a: Score, b: Score) => b.points - a.points);
            if (index % 2 === 0) {
                player2BumpData.forEach((val: Array<BumpInputDatum>, key: string) => {
                    const idx: number = currentScores.findIndex((s: Score) => s.name === key);
                    if (idx === -1) {
                        val.push({ x: index / 2, y: null });
                    } else {
                        val.push({ x: index / 2, y: +idx + 1 });
                    }
                });
            }
        });

        const bumpData: Array<BumpInputSerie> = [];
        player2BumpData.forEach((val: Array<BumpInputDatum>, key: string) => {
            bumpData.push({ id: key, data: val });
        });
        return bumpData;
    }

    getPlacePieData(): Array<{
        id: string,
        label: string,
        value: number,
    }> {
        const placeData: Array<{
            id: string,
            label: string,
            value: number,
        }> = [];
        for (const g in this.games) {
            const game: Game = this.games[g];
            const idx: number = placeData.findIndex((val) => val.id === game.place);
            if (idx === -1) {
                placeData.push({
                    id: game.place,
                    label: game.place,
                    value: 1,
                });
            } else {
                placeData[idx].value += 1;
            }
        }
        return placeData;
    }

    getPlayerPoints(player: string): number {
        let points: number = 0;
        for (const g in this.games) {
            const game: Game = this.games[g];
            for (const s in game.scores) {
                const score: Score = game.scores[s];
                if (score.name === player) {
                    points = +points + +score.points;
                }
            }
        }
        return points;
    }

    getRank(player: string): number {
        const scores: Array<Score> = this.getScores();
        return scores.findIndex((score: Score) => score.name === player) + 1;
    }

    getNumberOfGames(player: string): number {
        return this.games.filter((game: Game) => game.scores.filter((score: Score) => score.name === player).length > 0).length;
    }

    getAvgScore(player: string): number {
        const points: number = this.getPlayerPoints(player);
        const numGames: number = this.getNumberOfGames(player);
        return points / numGames;
    }

    getLineData(player: string): Array<Serie> {
        const pointsOverTime: Array<Datum> = [];
        let points: number = 0;
        for (const g in this.games) {
            const game: Game = this.games[g];
            const idx: number = game.scores.findIndex((score: Score) => score.name === player);
            if (idx === -1) continue;
            points = +points + +game.scores[idx].points;
            pointsOverTime.push({
                x: game.date.getDate() + '.' + (game.date.getMonth() + 1) + '.',
                y: points,
            });
        }

        const serie: Serie = {
            id: 'Punkte',
            data: pointsOverTime,
        };
        return [serie];
    }

    getRankBarData(player: string): Array<{
        rank: string | number,
        count: number,
    }> {
        const rankData: Array<{
            rank: string | number,
            count: number,
        }> = [];

        for (const g in this.games) {
            const game: Game = this.games[g];
            const scoreIdx: number = game.scores.findIndex((score: Score) => score.name === player);
            if (scoreIdx === -1) continue;
            const sortedScores: Array<Score> =
                game.scores.sort((a: Score, b: Score) => b.points - a.points);
            const rank: number = sortedScores.findIndex((score: Score) => score.name === player) + 1;
            const rankIdx: number = rankData.findIndex((val) => val.rank === rank);
            if (rankIdx === -1) {
                rankData.push({
                    rank: rank,
                    count: 1
                });
            } else {
                rankData[rankIdx].count += 1;
            }
        }
        const sortedRankData = rankData.sort((a, b) => +a.rank - +b.rank);
        for (let i = 0; i < sortedRankData.length; i++) {
            const val = sortedRankData[i];
            if (val.rank !== i + 1) {
                sortedRankData.splice(i, 0, {
                    rank: i + 1,
                    count: 0
                });
            }
        }
        sortedRankData.forEach((val) => val.rank = val.rank + ". Platz");
        return sortedRankData;
    }

    updateData(): void {
        const corePlayersMap: Map<string, number> = new Map();
        for (const g in this.games) {
            const game: Game = this.games[g];
            for (const s in game.scores) {
                const score: Score = game.scores[s];
                this.players.push(score.name);

                // count points
                if (!this.pointsPerPlayer.has(score.name)) {
                    this.pointsPerPlayer.set(score.name, 0);
                }
                const oldPoints: number = this.pointsPerPlayer.get(score.name)!;
                const newPoints: number = +score.points + +oldPoints;
                this.pointsPerPlayer.set(score.name, newPoints);

                // count games
                if (!corePlayersMap.has(score.name)) {
                    corePlayersMap.set(score.name, 0);
                }
                const oldCount: number = corePlayersMap.get(score.name)!;
                corePlayersMap.set(score.name, +oldCount + 1);
            }
        }
        corePlayersMap.forEach((val: number, key: string) => {
            if (val > 3) {
                this.corePlayers.push(key);
            }
        });
    }

};