import React from 'react';
import { DataManager } from '../../service/dataManager';
import { InfoBox } from '../components/InfoRow';
import { Title } from '../components/Title';
import { Award, CalendarWeek, Trophy } from 'react-bootstrap-icons';
import { MyLine } from '../components/nivo/MyLine';
import { Subtitle } from '../components/Subtitle';
import { MyBar } from '../components/nivo/MyBar';

interface PlayerStatsProps {
    player: string;
}

export class PlayerStats extends React.Component<PlayerStatsProps, {}> {

    render() {
        return (
            <div>

                {/* <div className="container">
                    <Title title={`${this.state.selected}'s 2020`} />
                </div> */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <InfoBox leading={<Trophy size={60} />} title={DataManager.get().getPlayerPoints(this.props.player).toString()} subtitle="Punkte" />
                        </div>
                        <div className="col">
                            <InfoBox leading={<Award size={60} />} title={DataManager.get().getRank(this.props.player).toString() + '.'} subtitle="Platz" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <InfoBox leading={<CalendarWeek size={60} />} title={DataManager.get().getNumberOfGames(this.props.player).toString()} subtitle="Spiele" />
                        </div>
                        <div className="col">
                            <InfoBox leading="Ø" title={DataManager.get().getAvgScore(this.props.player).toFixed(1)} subtitle="Ø-Punkte" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <Title title="Punkte übers Jahr" />
                    <Subtitle subtitle={`Der Punkteverlauf von ${this.props.player} über das Jahr`} />
                    <div className="horizontal-scroll">
                        <MyLine data={DataManager.get().getLineData(this.props.player)} />
                    </div>
                </div>

                <div className="container-fluid">
                    <Title title="Platzierungen" />
                    <Subtitle subtitle={`Die Anzahl der Platzierungen von ${this.props.player}`} />
                    <div className="horizontal-scroll">
                        <MyBar
                            data={DataManager.get().getRankBarData(this.props.player)}
                            indexBy="rank"
                            keys={["count"]}
                        />
                    </div>
                </div>

            </div>
        );
    }
}