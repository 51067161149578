import { ResponsiveBar } from '@nivo/bar';
import React from 'react';

interface MyBarProps {
    data: Array<object>;
    keys: Array<string>;
    indexBy: string;
}

export class MyBar extends React.Component<MyBarProps, {}> {

    render() {
        return (
            <div className="row">
                <div className="col" style={{ height: 500, width: 500 }}>
                    <ResponsiveBar
                        data={this.props.data}
                        keys={this.props.keys}
                        indexBy={this.props.indexBy}

                        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                        padding={0.3}

                        colors={{ scheme: 'dark2' }}

                        borderRadius={3}
                        borderWidth={3}

                        enableGridY={false}

                        axisRight={{ orient: "right" }}
                    />
                </div>
            </div>
        );
    }
}