import { ResponsiveLine, Serie } from '@nivo/line';
import React from 'react';

interface MyLineProps {
    data: Array<Serie>;
}

export class MyLine extends React.Component<MyLineProps, {}> {


    render() {
        return (
            <div className="row">
                <div className="col" style={{ height: 600, width: 2000 }}>
                    <ResponsiveLine
                        data={this.props.data}

                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}

                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', reverse: false }}
                        yFormat=" >-.2d"

                        colors={{ scheme: 'dark2' }}
                        lineWidth={4}

                        axisTop={{ orient: 'top' }}
                        axisRight={{ orient: 'right' }}
                        enableGridY={false}

                        pointSize={10}
                        pointBorderWidth={3}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointColor='#333333'
                        enablePointLabel={true}
                        pointLabelYOffset={-12}
                        useMesh={true}

                        isInteractive={false}
                    />
                </div>
            </div>
        );
    }
}