import React from 'react';

interface SelectableProps {
    options: Array<string>;
    selected: string;
    handleChange: (selected: string) => void;
}

export class Selectable extends React.Component<SelectableProps, {}> {

    constructor(props: Readonly<SelectableProps>) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        const selectedIdx: number = event.target.selectedIndex;
        const selected: string = this.props.options[selectedIdx];
        this.props.handleChange(selected);
    }

    render() {
        return (
            <div className="row my-2">
                <div className="col">
                    <select
                        className="form-select form-select-lg"
                        aria-label="Spielerauswahl"
                        value={this.props.selected}
                        onChange={this.handleChange}
                    >
                        {this.props.options.map((val: string) => {
                            return (
                                <option
                                    key={val + "Option"}
                                    value={val}
                                >
                                    {val}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div >
        );
    }
}