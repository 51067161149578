import React from 'react';
import { BumpInputSerie, ResponsiveBump } from '@nivo/bump';

interface MyBumpProps {
    data: BumpInputSerie[],
}

export class MyBump extends React.Component<MyBumpProps, {}> {

    render() {
        return (
            <div className="row">
                <div className="col" style={{ height: 600, width: 2000}}>
                    <ResponsiveBump
                        data={this.props.data}

                        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}

                        startLabel="Rang"
                        colors={{ scheme: 'dark2' }}
                        enableGridY={false}

                        pointSize={10}
                        pointBorderWidth={3}
                        pointBorderColor={{ from: 'serie.color', modifiers: [] }}
                        pointColor='#333333'
                        activePointSize={15}
                        activePointBorderWidth={5}
                        inactivePointSize={0}

                        axisTop={{ enable: false }}
                        axisBottom={{ enable: false }}

                    />
                </div>
            </div>
        );
    }
}