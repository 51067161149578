import React from 'react';

interface TitleProps {
    title: string;
    size: number;
}

export class Title extends React.Component<TitleProps, {}> {
    public static defaultProps = {
        size: 4,
    };

    render() {
        return (
            <div className="row justify-content-center mt-4">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <h1 className={"display-" + this.props.size}>{this.props.title}</h1>
                </div>
            </div>
        );
    }
}