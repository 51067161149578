import React from 'react';
import './App.css';
import { Main } from './home/main/Main';
import { Game } from './model/game';
import { DataManager } from './service/dataManager';
import { FirestoreService } from './service/firestoreService';

interface AppState {
  isGamesLoaded: boolean;
}

class App extends React.Component<{}, AppState> {

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      isGamesLoaded: false,
    };
  }

  componentDidMount() {
    if (!FirestoreService.isInitialized) {
      FirestoreService.initialize();
    }
    FirestoreService.readGames()
      .then((val: Array<Game>) => {
        DataManager.get().setGames(val);
        this.setState({
          isGamesLoaded: true,
        });
      });
  }

  render() {

    const { isGamesLoaded } = this.state;

    if (!isGamesLoaded) {
      return (
        <div>Loading...</div>
      );
    }

    const games: Array<Game> = DataManager.get().getGames();
    if (games.length === 0) {
      return (
        <div>Found no data</div>
      );
    }

    return (
      <Main />
    );
  }
}

export default App;
