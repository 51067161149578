import React from 'react';
import { Score } from '../../model/score';

interface ScoreTableProps {
    scores: Array<Score>;
}

export class ScoreTable extends React.Component<ScoreTableProps, {}> {

    render() {

        let { scores } = this.props;
        scores = scores.sort((a: Score, b: Score) => b.points - a.points);

        return (
            <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <table className="table table-striped table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Punkte</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scores.map((score: Score, idx: number) => {
                                return (
                                    <tr key={score.name + "Row"}>
                                        <th scope="row">{idx + 1}</th>
                                        <td>{score.name}</td>
                                        <td>{score.points}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
}