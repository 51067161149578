import React from 'react';

interface SubtitleProps {
    subtitle: string;
}

export class Subtitle extends React.Component<SubtitleProps, {}> {

    render() {
        return (
            <div className="row justify-content-center mt-0">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <p className="lead">{this.props.subtitle}</p>
                </div>
            </div>
        );
    }
}