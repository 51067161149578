import firebase from 'firebase';
import { Score } from './score';

export class Game {
    date: Date;
    place: string;
    scores: Array<Score>;


    constructor(date: Date, place: string, scores: Array<Score>) {
        this.date = date;
        this.place = place;
        this.scores = scores;
    }
}

export const gameConverter = {
    toFirestore(game: Game): firebase.firestore.DocumentData {
        return {
            timestamp: game.date.toISOString(),
            place: game.place,
            players: game.scores.map((s: Score) => s.toMap()),
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Game {
        const data = snapshot.data(options)!;
        return new Game(
            new Date(data.timestamp),
            (data.place as string).trim(),
            data.players.map((val: { name: string, score: number; }) => Score.fromMap(val))
        );
    }
};