import React from 'react';
import { Score } from '../../model/score';
import { DataManager } from '../../service/dataManager';
import { MyBump } from '../components/nivo/MyBump';
import { ScoreTable } from '../components/ScoreTable';
import { PlayerStats } from './PlayerStats';
import { Title } from '../components/Title';
import { Selectable } from '../components/form/Selectable';
import { MyPie } from '../components/nivo/MyPie';
import { Subtitle } from '../components/Subtitle';

interface MainState {
    players: Array<string>;
    selected: string;
}


export class Main extends React.Component<{}, MainState> {

    constructor(props: Readonly<{}>) {
        super(props);

        const playerArr: Array<string> = DataManager.get().getPlayers();
        this.state = {
            players: playerArr,
            selected: playerArr[0],
        };

        this.handleSelectedChange = this.handleSelectedChange.bind(this);
    }

    handleSelectedChange(selectedPlayer: string): void {
        this.setState({
            selected: selectedPlayer
        });
    }

    render() {

        const scores: Array<Score> = DataManager.get().getScores();

        return (
            <div>

                <div className="container">
                    <div className="row my-4">
                        <div className="col d-flex justify-content-center">
                            <h1 className="display-1 ">Doppelkopf 2020 in a nutshell</h1>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <Title title="Finaler Punktestand" />
                    <Subtitle subtitle="Herzlichen Glückwunsch an Carlos mit 1118 Punkten" />
                    <ScoreTable scores={scores} />
                </div>

                <div className="container-fluid">
                    <Title title="Rangliste übers Jahr" />
                    <Subtitle subtitle="Die Platzierungen der Spieler:innen über das Jahr" />
                    <div className="horizontal-scroll">
                        <MyBump data={DataManager.get().getRankingBumpData()} />
                    </div>
                </div>

                <div className="container">
                    <Title title="Orte des Geschehens" />
                    <Subtitle subtitle="Die Verteilung der Orte, an denen gespielt wurde" />
                    <MyPie data={DataManager.get().getPlacePieData()} />
                </div>

                <hr />

                <div className="container mb-5">
                    <Title title="Einzelstatistiken" size={2} />
                    <Subtitle subtitle="Wähle eine:n Spieler:in aus um Statistiken für diese Person zu sehen" />
                    <Selectable
                        options={this.state.players}
                        selected={this.state.selected}
                        handleChange={this.handleSelectedChange} />
                </div>

                <PlayerStats player={this.state.selected} />

            </div>
        );
    }

}