import { ResponsivePie } from '@nivo/pie';
import React from 'react';

interface MyPieProps {
    data: Array<{
        id: string;
        label: string;
        value: number;
    }>;
}

export class MyPie extends React.Component<MyPieProps, {}> {


    render() {
        return (
            <div className="row">
                <div className="col" style={{ height: 300 }}>
                    <ResponsivePie
                        data={this.props.data}

                        margin={{ top: 30, right: 20, bottom: 30, left: 20 }}

                        innerRadius={0.4}
                        padAngle={3}
                        cornerRadius={3}
                        borderWidth={3}

                        sortByValue={true}

                        colors={{ scheme: 'dark2' }}

                        enableRadialLabels={false}
                        sliceLabelsRadiusOffset={1.25}
                    />
                </div>
            </div>
        );
    }
}